@tailwind base;
@tailwind components;
@tailwind utilities;


/****** FONTS ******/


@font-face {
  font-family: 'NHaasGroteskDSPRO55';
  src: url('./assets/fonts/NHaasGroteskDSPro-55Rg.otf') format('opentype');
}
@font-face {
  font-family: 'NHaasGroteskPRO65MD';
  src: url('./assets/fonts/NHaasGroteskTXPro-65Md.otf') format('opentype');
}

@font-face {
  font-family: 'ITC';
  src: url('./assets/fonts/ITCGaramondStd-LtIta.ttf') format('opentype');
}



.font-NHAASDS {
  font-family:"NHaasGroteskDSPRO55";
}

.font-NHAASPRO {
  font-family:"NHaasGroteskPRO65MD";
}





/****** BODY ******/



body, html {
  color:#212121;
  overflow-x: hidden;
  overflow-y: auto; /* Garde le scroll vertical actif */
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto; /* Ne force pas la hauteur totale */
  overflow-x: hidden; /* Bloque le débordement horizontal */
  overflow-y: auto;   /* Assure le scroll vertical */
  scroll-behavior: smooth;
  background-color: #F6F4F4;

}
  
main {
  margin: 0;
  padding: 0;
}

h1, span {
  line-height: 1;
}

h2 {
  line-height: 1.1;
}

/****** BUTTON ******/
.custom-button {
  display: inline-block;
  transition: all 0.2s ease; /* Transition pour un effet fluide */
  position: relative;
  opacity: 0; /* Masquer avant l'animation */
}

.custom-button::after {
  content: "➔"; /* Flèche */
  position: absolute;
  bottom: 0%; /* Position parfaite conservée */
  right: 0%; /* Toujours à droite */
  font-size: 1.2rem; /* Taille de la flèche */
  color: #212121; /* Couleur de la flèche */
  opacity: 0; /* Invisible par défaut */
  transform: translate(10px, 10px) rotate(-45deg); /* Pointe vers le haut à droite */
  transition: all 0.3s ease-in-out; /* Animation fluide */
}
.custom-button:hover::after {
  opacity: 1; /* Rend la flèche visible */
  transform: translate(-10px, -10px) rotate(-45deg); /* Conserve l'orientation */
}





.img-wrap img {
  transition: transform 0.5s;  
}

.img-wrap img:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }

  .flowers {
    pointer-events: auto !important;
  }

/****** CURSOR & BG  ******/



 svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index:0;
}

line {
  stroke: url(#cursor-gradient); 
  stroke-width: 20;
  stroke-linecap: round;
} 

/****** TEXT ******/

.text-hidden {
  visibility: hidden; 
}

.word {
  display: inline-block;
  background: linear-gradient(90deg, #000 50%,#d3d3d3 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  transition: background-position 0.6s ease; /* Transition fluide sur le changement de position du dégradé */
  background-size: 200% 100%;
  background-position: 100% 0; /* Position de départ du dégradé, mot entièrement en gris */
}

.word1 {
  
  display: inline-block;
  background: linear-gradient(90deg, #000 50%,#d3d3d3 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: black;
  transition: background-position 0.6s ease; /* Transition fluide sur le changement de position du dégradé */
  background-size: 200% 100%;
  background-position: 100% 0; /* Position de départ du dégradé, mot entièrement en gris */
}


.word.active {
  background-position: 0 0; /* Le dégradé bouge pour remplir progressivement le mot de noir */
}


/****** SCROLL ******/


.scrollbar-none {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.scroll-smooth {
  scroll-behavior: smooth;
}

/****** SKILLS SECTION ******/


.skills-section {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.skills-section.active {
  opacity: 1;
  transform: translateY(0);
}





.tab {
  transition: color 0.15s ease-in;
}

.glider {
  z-index: 1;
  transition: transform 0.25s ease-out;
  box-shadow: 0 5px 13px rgba(255, 165, 0, 0.5);
}



@media (max-width: 768px) {
 

  svg {
    display: none;
  }

  line {
    display: none;
  }
}

